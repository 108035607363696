import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { SocialLinks } from "@userbrain/website-ui";
import * as styles from "./styles.module.css";
import { AutoLink } from "../helpers/AutoLink";
import { ButtonLink } from "@userbrain/website-ui";

export function LinkCollection(props) {
  const { className, links, ...containerProps } = props;

  return (
    <div className={cn(className, styles.linkCollection)} {...containerProps}>
      {links.map((item, index) => (
        <div className={styles.link} key={index}>
          <h2 className={styles.linkTitle}>{item.title}</h2>
          {item.socialLinks ? (
            <SocialLinks dark={true} />
          ) : (
            <ButtonLink
              customTag={AutoLink}
              to={item.linkTo}
              href={item.linkHref}
            >
              {item.linkText}
            </ButtonLink>
          )}
        </div>
      ))}
    </div>
  );
}
