import React from "react";

import { concatClassNames as cn } from "@sys42/utils";

import { Overline, urlTesterRegistration } from "@userbrain/website-ui";

import Layout from "../layouts/layout";

import { ContactBoxes } from "../components/ContactBoxes";
import { ContentCentered } from "../components/ContentCentered";
import { HeaderTitleBlank } from "../components/HeaderTitleBlank";
import { LinkCollection } from "../components/LinkCollection";

import * as helperStyles from "./helpers.module.css";
import * as styles from "./styles/contact.module.css";

export default function Analyze() {
  return (
    <Layout activeMenuItem={"contact"} title={"Contact"}>
      <article>
        <HeaderTitleBlank overline={"Contact"}>
          <span className={styles.highlight}>Questions?</span>
          <br />
          <span className={helperStyles.h1Serif}>We’ve got the answers.</span>
        </HeaderTitleBlank>

        <div className={cn(helperStyles.container)}>
          <ContactBoxes className={helperStyles.spacingMd} />

          <LinkCollection
            className={cn(helperStyles.spacingLg, styles.linkCollection)}
            links={[
              {
                title: "Want to apply to become a tester?",
                linkText: "Get paid to test",
                linkHref: urlTesterRegistration,
              },
              /*Todo: reactivate {
              title: 'Want to make a press inquiry?',
              linkText: 'Press hub',
              linkTo: '/press',
            },*/
              {
                title: "Want to work at Userbrain?",
                linkText: "View open positions",
                linkTo: "/careers",
              },
              {
                title: "Follow us on:",
                socialLinks: true,
              },
            ]}
          />

          <ContentCentered
            className={helperStyles.spacingLg}
            textContent={
              <>
                <Overline customTag={"h2"}>Mailing Address</Overline>
                <p style={{ margin: "12px 0 0 0" }}>
                  Userbrain GmbH
                  <br />
                  Frauengasse 7<br />
                  8010 Graz, Austria
                </p>
              </>
            }
          />
        </div>
      </article>
    </Layout>
  );
}
